/* General */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,body {
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Work Sans', sans-serif;
    font-size: 17px;
}

strong {
    font-weight: 600;
}

* {
    font-family: 'Work Sans', sans-serif;
}

.name-staff {
    color: #FBBF24 !important;
}

.name-customer {
    color: #059669 !important;
}

.chatlog__embed-description p {
    font-weight: 400;
    white-space: pre-line;
    max-width: 100%;
}

p code {
    background-color: #2f3136 !important;
    border: none;
    color: #b9bbbe !important;
    margin: 0;
    font-family: "Consolas", "Courier New", Courier, monospace;
    padding: 1.5px;
    border-radius: 2.5px;
    font-size: 0.8rem;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    object-fit: contain;
}

.logo {
    padding: 20px 0 0 0;
}

.h-full {
    height: 100%;
}

p {
    margin: 0;
}

.footer {
    padding: 15px 20px;
}

/* Start main page styles */

.logo {
    position: fixed;
    top: 5px;
    left: 20px;
}

.main_page {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.main_page .search_container {
    width: 26rem;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.main_page .search_container > div {
    width: 100%;
}

.main_page .search_container h1 {
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: rgb(241, 241, 241);
}

.main_page .form form {
    display: flex;
}

.main_page .form form input {
    padding: 15px;
    background-color: #171f33;
    border: none;
    border-radius: 8px 0 0 8px;
    color: white;
    outline: none;
    flex-grow: 1;
    font-size: 16px;
}

.main_page .form form button {
    padding: 15px 25px;
    background-color: #059669;
    border: none;
    border-radius: 0 8px 8px 0;
    color: white;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 100ms;
}

.main_page .form form button:hover {
    background-color: #047857;
}

.main_page .notification {
    background-color: #DC2626;
    color: white;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 18px;
    text-align: left;
    font-size: 16px;
}

.ticketlog_logo {
    padding: 25px 20px;
}

/* End main page styles */


.markdown {
    max-width: 100%;
    line-height: 1.3;
    overflow-wrap: break-word;
}

.markdown.embed-title p {
    margin: 0;
    font-size: 1rem;
}

.markdown.embed-title p strong {
    font-weight: 600;
}

.markdown p:first-child {
    margin-top: 0;
}

.markdown p:last-child {
    margin-bottom: 0;
}

.preserve-whitespace {
    white-space: pre-wrap;
}

.spoiler {
    /* width: fit-content; */
    display: inline-block;
    /* This is more consistent across browsers, the old attribute worked well under Chrome but not FireFox. */
}

.spoiler--hidden {
    cursor: pointer;
}

.spoiler-text {
    border-radius: 3px;
}

.spoiler--hidden .spoiler-text {
    color: rgba(0, 0, 0, 0);
}

.spoiler--hidden .spoiler-text::selection {
    color: rgba(0, 0, 0, 0);
}

.spoiler-image {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}

.spoiler--hidden .spoiler-image {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

.spoiler--hidden .spoiler-image * {
    filter: blur(44px);
}

.spoiler--hidden .spoiler-image:after {
    content: "SPOILER";
    color: #dcddde;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    /* padding: 0.5em 0.7em; */
    padding: 100%;
    /* This ruins those beutifully rounded buttons, but it's needed to prevent a FireFox bug with video and audio elemnts. */
    /* The bug is that you can click trough the spoiler layer and play the video or audio, I could not identify the cause. */
    /* I leave this here, in case someone is brave enough to venture in to madness that is undocumented browser behaviour. */
    border-radius: 20px;
    letter-spacing: 0.05em;
    font-size: 0.9em;
}

.spoiler--hidden:hover .spoiler-image:after {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.9);
}

.quote {
    margin: 0.1em 0;
    padding-left: 0.6em;
    border-left: 4px solid;
    border-radius: 3px;
}

.pre {
    font-family: "Consolas", "Courier New", Courier, monospace;
}

.pre--multiline {
    margin-top: 0.25em;
    padding: 0.5em;
    border: 2px solid;
    border-radius: 5px;
}

.pre--inline {
    padding: 2px;
    border-radius: 3px;
    font-size: 0.85em;
}

.mention {
    border-radius: 3px;
    padding: 0 2px;
    color: #7289da;
    background: rgba(114, 137, 218, .1);
    font-weight: 500;
}

.emoji {
    width: 1.25em;
    height: 1.25em;
    margin: 0 0.06em;
    vertical-align: -0.4em;
}

.emoji--small {
    width: 1em;
    height: 1em;
}

.emoji--large {
    width: 2.8em;
    height: 2.8em;
}

/* Preamble */

.preamble {
    display: grid;
    margin: 0 0.3em 0.6em 0.3em;
    max-width: 100%;
    grid-template-columns: auto 1fr;
}

.preamble__guild-icon-container {
    grid-column: 1;
}

.preamble__entries-container {
    grid-column: 2;
    margin-left: 0.6em;
}

.preamble__entry {
    font-size: 1.4em;
}

.preamble__entry--small {
    font-size: 1em;
}

/* Chatlog */

.chatlog {
    max-width: 100%;
}

.chatlog__message-group {
    display: grid;
    padding: 0.9em 0.6em;
    grid-template-columns: auto 1fr;
}

.chatlog__author-avatar-container {
    grid-column: 1;
    width: 40px;
    height: 40px;
}

.chatlog__author-avatar {
    border-radius: 50%;
    height: 40px;
}

.chatlog__messages {
    grid-column: 2;
    margin-left: 1.2em;
    min-width: 50%;
}

.chatlog__author-name {
    font-weight: 500;
}

.chatlog__timestamp {
    margin-left: 0.4em;
    font-size: 0.75em;
}

.chatlog__message {
    padding: 0.1em 0.3em;
    margin: 0 -0.3em;
    background-color: transparent;
    transition: background-color 1s ease;
    max-width: 100%;
}

.chatlog__content {
    font-size: 0.95em;
    word-wrap: break-word;
    padding-top: 2px;
}

.chatlog__edited-timestamp {
    margin-left: 0.15em;
    font-size: 0.8em;
}

.chatlog__attachment {
    margin-top: 0.3em;
}

.chatlog__attachment-thumbnail {
    vertical-align: top;
    max-width: 45vw;
    max-height: 500px;
    border-radius: 3px;
}

.chatlog__embed {
    display: flex;
    margin-top: 0.3em;
    max-width: 520px;
}

.chatlog__embed-color-pill {
    flex-shrink: 0;
    width: 0.25em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.chatlog__embed-content-container {
    display: flex;
    flex-direction: column;
    padding: 0.5em 0.6em;
    border: 1px solid;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.chatlog__embed-content {
    display: flex;
    width: 100%;
}

.chatlog__embed-text {
    flex: 1;
}

.chatlog__embed-author {
    display: flex;
    margin-bottom: 0.3em;
    align-items: center;
}

.chatlog__embed-author-icon {
    margin-right: 0.5em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.chatlog__embed-author-name {
    font-size: 0.875em;
    font-weight: 600;
}

.chatlog__embed-title {
    margin-bottom: 0.2em;
    font-size: 0.875em;
    font-weight: 600;
}

.chatlog__embed-description {
    font-weight: 500;
    font-size: 0.85em;
}

.chatlog__embed-description p:first-child strong:first-child {
    font-size: 1rem;
    margin-bottom: 8px;
    display: inline-block;
    color: #fff;
}

.chatlog__embed-fields {
    display: flex;
    flex-wrap: wrap;
}

.chatlog__embed-field {
    flex: 0;
    min-width: 100%;
    max-width: 506px;
    padding-top: 0.6em;
    font-size: 0.875em;
}

.chatlog__embed-field--inline {
    flex: 1;
    flex-basis: auto;
    min-width: 150px;
}

.chatlog__embed-field-name {
    margin-bottom: 0.2em;
    font-weight: 600;
}

.chatlog__embed-field-value {
    font-weight: 500;
}

.chatlog__embed-thumbnail {
    flex: 0;
    margin-left: 1.2em;
    max-width: 80px;
    max-height: 80px;
    border-radius: 3px;
}

.chatlog__embed-image-container {
    margin-top: 0.6em;
}

.chatlog__embed-image {
    max-width: 100%;
    max-height: 400px;
    border-radius: 3px;
    margin-top: 8px;
}

.chatlog__embed-footer {
    margin-top: 0.6em;
}

.chatlog__embed-footer-icon {
    margin-right: 0.2em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    vertical-align: middle;
}

.chatlog__embed-footer-text {
    font-size: 0.75em;
    font-weight: 500;
}

.chatlog__reactions {
    display: flex;
}

.chatlog__reaction {
    display: flex;
    align-items: center;
    margin: 0.35em 0.1em 0.1em 0.1em;
    padding: 0.2em 0.35em;
    border-radius: 3px;
}

.chatlog__reaction-count {
    min-width: 9px;
    margin-left: 0.35em;
    font-size: 0.875em;
}

.chatlog__bot-tag {
    position: relative;
    top: -.1em;
    margin-left: 0.4em;
    padding: 0.05em 0.3em;
    border-radius: 3px;
    vertical-align: middle;
    line-height: 1.3;
    background: #059669;
    color: #ffffff;
    font-size: 0.625em;
    font-weight: 500;
}

.chatlog__staff-tag {
    position: relative;
    top: -.1em;
    margin-left: 0.4em;
    padding: 0.05em 0.3em;
    border-radius: 3px;
    vertical-align: middle;
    line-height: 1.3;
    background: #059669;
    color: #ffffff;
    font-size: 0.625em;
    font-weight: 500;
}

.chatlog__tag {
    position: relative;
    top: -.1em;
    margin-left: 0.4em;
    padding: 0.05em 0.3em;
    border-radius: 3px;
    vertical-align: middle;
    line-height: 1.3;
    font-size: 0.625em;
    font-weight: 500;
}

.chatlog__reply {
    padding-bottom: 2px;
    padding-top: 7px;
    background-color: transparent;
    border: none;
    display: block;
    color: rgb(185, 187, 190);
    padding: 0;
    padding: 8px 0 0 0;
}

.chatlog__reply:hover {
    text-decoration: none;
    color: #ddd;
}

.chatlog__reply strong {
    margin-right: 5px;
    font-weight: 500;
}

.chatlog__reply strong, .chatlog__reply span {
    margin-top: -10px !important;
    font-size: 0.95rem;
    display: inline-block;
    vertical-align: middle;
}

.chatlog__reply img {
    max-height: 18px;
    border-radius: 50%;
    margin-right: 6px;
}

/* Postamble */

.postamble {
    margin: 1.4em 0.3em 0.6em 0.3em;
    padding: 1rem 0;
    border-top: 1px solid;
}

/* General */

body {
    background-color: #0c111bf8;
    color: #dcddde;
}

a {
    color: #0096cf;
}

.spoiler-text {
    background-color: rgba(255, 255, 255, 0.1);
}

.spoiler--hidden .spoiler-text {
    background-color: #202225;
}

.spoiler--hidden:hover .spoiler-text {
    background-color: rgba(32, 34, 37, 0.8);
}

.quote {
    border-color: #4f545c;
}

.pre {
    background-color: #2f3136 !important;
}

.pre--multiline {
    border-color: #282b30 !important;
    color: #b9bbbe !important;
}

pre {
    margin: 0;
}

code {
    background-color: #2f3136 !important;
    border-color: #282b30 !important;
    color: #b9bbbe !important;
    display: inline-block;
    font-family: "Consolas", "Courier New", Courier, monospace;
    margin-top: 0.25em;
    padding: 0.5em;
    border: 2px solid;
    border-radius: 5px;
}

/* === Preamble === */

.preamble__entry {
    color: #ffffff;
}

/* Chatlog */

.chatlog__message-group {
    border-color: rgba(255, 255, 255, 0.1);
}

.chatlog__message-group:hover {
    background-color: rgba(0, 0, 0, 0.125);
}

.chatlog__author-name {
    color: #ffffff;
}

.chatlog__timestamp {
    color: rgba(255, 255, 255, 0.2);
}

.chatlog__message--highlighted {
    background-color: rgba(114, 137, 218, 0.2) !important;
}

.chatlog__message--pinned {
    background-color: rgba(249, 168, 37, 0.05);
}

.chatlog__edited-timestamp {
    color: rgba(255, 255, 255, 0.2);
}

.chatlog__embed-color-pill--default {
    background-color: rgba(79, 84, 92, 1);
}

.chatlog__embed-content-container {
    background-color: rgba(46, 48, 54, 0.3);
    border-color: rgba(46, 48, 54, 0.6);
}

.chatlog__embed-author-name {
    color: #ffffff;
}

.chatlog__embed-author-name-link {
    color: #ffffff;
}

.chatlog__embed-title {
    color: #ffffff;
}

.chatlog__embed-description {
    color: rgba(255, 255, 255, 0.6);
}

.chatlog__embed-field-name {
    color: #ffffff;
}

.chatlog__embed-field-value {
    color: rgba(255, 255, 255, 0.6);
}

.chatlog__embed-footer {
    color: rgba(255, 255, 255, 0.6);
}

.chatlog__reaction {
    background-color: rgba(255, 255, 255, 0.05);
}

.chatlog__reaction-count {
    color: rgba(255, 255, 255, 0.3);
}

/* Postamble */

.postamble {
    border-color: rgba(255, 255, 255, 0.1);
}

.postamble__entry {
    color: #ffffff;
}

.text-grey {
    color: rgba(255, 255, 255, 0.6);
}

.text-small {
    font-size: 1rem;
}

.less-strong {
    font-weight: 500;
}

.searchform label {
    display: block;
}

.searchform input {
    display: inline-block;
    margin: 8px 0;
    box-sizing: border-box;
    width: 300px;
    border: none;
    background-color: rgb(64, 68, 75);
    color: white;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}

.searchform button {
    display: inline-block;
    width: 100px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    background-color: rgb(64, 68, 75);
    color: white;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    font-size: 16px;
}

.load {
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    align-items: center;
    justify-content: center;
}

.loader {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loader 0.6s linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  